<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Learning</Text>
      <Btn
        @click="to('/dashboard/learning/upload')"
        v-if="$store.getters.isAdmin"
        >Upload</Btn
      >
    </List>

    <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <!-- <div
          :class="{ active: videos.section == 'docs' }"
          @click="videos.section = 'docs'"
        >
          Docs
        </div> -->
        <div
          :class="{ active: videos.section == 'JAVA' }"
          @click="changeSection('JAVA')"
        >
          Programming
        </div>
        <div
          :class="{ active: videos.section == 'automation' }"
          @click="changeSection('automation')"
        >
          Automation
        </div>
        <div
          :class="{ active: videos.section == 'API' }"
          @click="changeSection('API')"
        >
          API
        </div>
        <div
          :class="{ active: videos.section == 'SDLC' }"
          @click="changeSection('SDLC')"
        >
          SDLC
        </div>
        <div
          :class="{ active: videos.section == 'draft' }"
          @click="changeSection('draft')"
          v-if="$store.getters.isAdmin"
        >
          Draft
        </div>
      </div>
      <div el="input-list">
        <label el="input">
          <input
            type="text"
            placeholder="Search..."
            v-model="videos.searchInput"
            el
          />
        </label>
      </div>
    </List>

    <table el="table">
      <thead>
        <tr>
          <!-- <th width="180px">Date</th> -->
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in filteredLearning"
          :key="item"
          @click="to('/dashboard/learning/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <!-- <td>{{ formatDate(item.date) }}</td> -->
          <td>{{ item.label }}</td>
        </tr>
        <!-- <tr>
          <td>Lowis</td>
          <td>Lane</td>
        </tr> -->
      </tbody>
    </table>
  </List>
</template>
<script>
import router from "@/router";
let filter = require("lodash/filter");
let lowerCase = require("lodash/lowerCase");
let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      groups: this.$store.state.main.groups,
      videos: {
        searchInput: "",
        section: "JAVA",
        filters: {
          tag: "JAVA",
          tags:[],
          status: "publish",
        },
      },
    };
  },
  watch: {
    "videos.section": function (val) {
      this.videos.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
    "groups.active": function (val) {
      this.videos.filters.tags = [val];
    },
  },
  methods: {
    formatDate(dateString) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(`${dateString} 00:00:00`);
      return `${dateString}, ${days[d.getDay()]}`;
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
    loadPrevSection(){
      let x = this;
      let section = localStorage.getItem("learningSection");
      if(section != null){
        x.videos.section = section;
      }
    },
    changeSection(section){
      let x = this;
      localStorage.setItem("learningSection", section);
      x.videos.section = section;
    },
  },
  computed: {
    filteredLearning() {
      // var input = this.modules.searchInput;
      let x = this;
      let checker = (arr, target) => target.every((v) => arr.includes(v));
      return filter(this.$store.state.learning.list, function (item) {
        if (x.videos.section === "draft") {
          return (
            item.status == "draft" &&
            lowerCase(`${item.date} ${item.label}`).search(
              lowerCase(x.videos.searchInput)
            ) > -1
          );
        } else {
          return (
            checker(item.tags, x.videos.filters.tags) == true &&
            includes(item.tags, x.videos.filters.tag) == true &&
            item.status == "publish" &&
            lowerCase(`${item.date} ${item.label}`).search(
              lowerCase(x.videos.searchInput)
            ) > -1
          );
        }
      });
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.$store.commit("learning/loadList");
    this.loadPrevSection();
    this.videos.filters.tags = [this.groups.active];
  },
};
</script>
<style lang="scss" scoped>
</style>
